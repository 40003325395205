import { FC, memo } from "react";
import styled from "styled-components";
import useDynamicSVGImport from "src/hooks/useDynamicSVGImport";
import classNames from "classnames";

type sizeType = "small" | "medium" | "large";

export type TypeIcon =
  | "account"
  | "add-circle"
  | "add"
  | "arrow-down"
  | "close-circle"
  | "close"
  | "delete"
  | "edit"
  | "form-help"
  | "form-mandatory"
  | "import"
  | "line-down"
  | "line-up"
  | "minimize"
  | "nav-campaign-groups"
  | "nav-campaigns"
  | "nav-dispositions"
  | "nav-event-closures"
  | "nav-operating-hours"
  | "nav-schemas"
  | "nav-users"
  | "nav-zones"
  | "pause-filled"
  | "pause"
  | "placeholder"
  | "play-filled"
  | "play"
  | "recycle"
  | "reorder"
  | "reset"
  | "save"
  | "search"
  | "sign-out"
  | "sort-table"
  | "stop-filled"
  | "stop"
  | "warning";

export interface IconTypeProps {
  width?: string | number;
  height?: string | number;
  size?: sizeType;
  type?: TypeIcon | string;
  fill?: string;
  className?: string;
  style?: React.CSSProperties;
}

const WrapperIconStyled = styled.div<IconTypeProps>`
  && {
    ${(props): string => {
      if (props.width && props.height) {
        return `
          width: ${props.width}${typeof props.width === "number" && "px;"}
          height: ${props.height}${typeof props.height === "number" && "px;"}
        `;
      }
      if (props?.size) {
        switch (props.size) {
          case "small":
            return `width: 16px; height: 16px;`;
          case "medium":
            return `width: 32px; height: 32px;`;
          case "large":
            return `width: 64px; height: 64px;`;
        }
      }
      return `width: 16px; height: 16px;`;
    }}
  }
  && svg {
    ${(props): string => {
      if (props.width && props.height) {
        return `
          width: ${props.width}${typeof props.width === "number" && "px;"}
          height: ${props.height}${typeof props.height === "number" && "px;"}
        `;
      }
      if (props?.size) {
        switch (props.size) {
          case "small":
            return `width: 16px; height: 16px;`;
          case "medium":
            return `width: 32px; height: 32px;`;
          case "large":
            return `width: 64px; height: 64px;`;
        }
      }
      return `width: 16px; height: 16px;`;
    }}

    *[fill^="#"] {
      ${({ fill }): string =>
        fill
          ? fill === "primary"
            ? "fill: var(--vf-primary-color);"
            : fill === "warning"
            ? "fill: var(--vf-warning-color);"
            : `fill: ${fill};`
          : ""}
    }
  }
`;

const Icon: FC<IconTypeProps> = ({ className, type, ...props }) => {
  const { Icon: IconComponent } = useDynamicSVGImport(type as string);
  return (
    <WrapperIconStyled {...props} className={classNames("anticon", className)}>
      {IconComponent && <IconComponent />}
    </WrapperIconStyled>
  );
};

export default memo(Icon);
