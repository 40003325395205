import { FC, useEffect, useRef, useState } from "react";

const useDynamicSVGImport = (name: string) => {
  const ImportedIconRef = useRef<FC<React.SVGProps<SVGSVGElement>> | any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | unknown>();

  useEffect(() => {
    setLoading(true);
    const importIcon = async (): Promise<void> => {
      try {
        ImportedIconRef.current = (await import(`../assets/icons/${name}.svg`)).ReactComponent;
      } catch (err) {
        setError(err);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    importIcon();

    return () => {
      setLoading(false);
    };
  }, [name]);

  return { loading, Icon: ImportedIconRef.current, error };
};

export default useDynamicSVGImport;
